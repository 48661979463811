import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_cell = _resolveComponent("van-cell")
  const _component_van_cell_group = _resolveComponent("van-cell-group")

  return (_openBlock(), _createBlock(_component_van_cell_group, { class: "uf-cell" }, {
    default: _withCtx(() => [
      _createVNode(_component_van_cell, {
        title: "认证为内部员工",
        "is-link": "",
        center: "",
        to: {
        name: _ctx.$RouterName.STAFF_AUTH
      }
      }, null, 8, ["to"]),
      _createVNode(_component_van_cell, {
        title: "认证为客户",
        "is-link": "",
        center: "",
        to: {
        name: _ctx.$RouterName.STORES_AUTH
      }
      }, null, 8, ["to"]),
      _createVNode(_component_van_cell, {
        title: "认证为供应商",
        "is-link": "",
        center: "",
        to: {
        name: _ctx.$RouterName.FACTORY_AUTH
      }
      }, null, 8, ["to"])
    ]),
    _: 1
  }))
}